/////////////////////////////////////////////
//Dependencies
import React from "react";

/////////////////////////////////////////////
//CSS
import "./ContactInfo.css";

/////////////////////////////////////////////
//Component

/**
 * ContactInfo component
 * 
 * @param {Object} props            - The component props.
 * @param {string} props.flag       - The flag icon name.
 * @param {string} props.country    - The country name.
 * @param {string} props.name       - The name of the contact or organization.
 * @param {string} props.address    - The address of the contact or organization.
 * @param {string} props.contact    - The contact person's name.
 * @param {string} props.mail       - The contact email address.
 * @param {string} props.phone      - The contact phone number.
 * @returns {JSX.Element}           - The rendered ContactInfo component.
 */
export default function ContactInfo({ flag, country, name, address, contact, mail, phone, phone2 = null }) {
    return (
        <div className="contact-info-card">
            {country && (
                <div className="contact-info-country">
                    <img src={`../img/icones/i-flag-${flag}.png`} alt={flag} />
                    <h2>{country}</h2>
                </div>
            )}
            {name && (
                <div className="info">
                    <img src="../img/icones/i-build.png" alt="name" />
                    <label>
                        {name}
                    </label>
                </div>
            )}
            {address && (
                <div className="info">
                    <img src="../img/icones/i-gps.png" alt="address" />
                    <label>
                        {address}
                    </label>
                </div>
            )}
            {contact && (
                <div className="info">
                    <img src="../img/icones/i-man.png" alt="contact" />
                    <label>
                        {contact}
                    </label>
                </div>
            )}
            {mail && (
                <div className="info">
                    <img src="../img/icones/i-mail.png" alt="mail" />
                    <a href={`mailto:${mail}`}>
                        <label className="cursor">
                            {mail}
                        </label>
                    </a>
                </div>
            )}
            {phone && (
                <div className="info">
                    <img src="../img/icones/i-phone.png" alt="phone" />
                    <div>
                        <a href={`tel:${phone}`}>
                            <label className="cursor">
                                {phone}
                            </label>
                        </a>
                        {phone2 && (
                            <div>
                                <a href={`tel:${phone2}`}>
                                    <label className="cursor">
                                        {phone2}
                                    </label>
                                </a>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};